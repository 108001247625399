import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronCircleRight, faChevronCircleDown} from '@fortawesome/free-solid-svg-icons'
import Img from "gatsby-image/withIEPolyfill"
import queryString from 'query-string'
import PqCheck from '../components/pqCheck'

import Layout from "../components/layout";
import SEO from "../components/seo";
import '../styles/services.css'

const Service = ({title, toggleClass, backgroundClass, children, onToggle, img}) => (
  <div className={`flex-col flex-center service-container ${toggleClass}`}>
    <div role="button" tabIndex={-1} className={`service-banner ${backgroundClass}`} onClick={onToggle} onKeyDown={onToggle}>
      <Img fluid={img.childImageSharp.fluid} className='service-bg-img' />
      <div className='service-bg-img-overlay'>
        <div className='service-bg-img-content-wrapper'>
          <div className='service-bg-img-content'>
            <h2>{title}</h2>
            <button onClick={onToggle}>
              <div className='service-toggle-icons'>
                <FontAwesomeIcon icon={faChevronCircleRight} size='2x' color='white' />
                <FontAwesomeIcon icon={faChevronCircleDown} size='2x' color='white' />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className='service-desc'>
      {children}
    </div>
  </div>
)

const ServiceQuality = ({text}) => (
  <li className='flex-row service-quality'>
    <PqCheck size='xs' vtPadding='0.25rem' hzPadding='0.5rem' />
    <div>{text}</div>
  </li>
)

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webMobileClass: 'service-collapsed',
      geospatialClass: 'service-collapsed',
      businessIntelligenceClass: 'service-collapsed',
      cloudClass: 'service-collapsed'
    }
  }

  componentDidMount() {
    const {location} = this.props;
    const parsedQueryString = location.search ? queryString.parse(location.search) : {};
    const typeClass = parsedQueryString.type && `${parsedQueryString.type}Class`;
    if (typeClass && this.state[typeClass]) {
      this.setState({[typeClass]: 'service-toggled'});
    }
  }

  componentDidUpdate(_, prevState) {
    const stateKeys = Object.keys(this.state);
    const keyToggled = stateKeys.find(key => prevState[key] === 'service-collapsed' && this.state[key] === 'service-toggled');
    if (keyToggled) {
      const bannerClass = keyToggled.replace("Class", "");
      const offset = document.getElementsByClassName(bannerClass)[0].offsetTop;
      window.scrollTo({
        top: offset,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  onToggleSection = (key) => {
    const stateKey = `${key}Class`;
    const value = this.state[stateKey] === 'service-collapsed' ? 'service-toggled' : 'service-collapsed';
    this.setState({
      [stateKey]: value
    })
  }

  render() {
    const {webMobileClass, geospatialClass, businessIntelligenceClass, cloudClass} = this.state;
    return (
      <Layout path={this.props.path}>
        <SEO title="Services" />
        <div className='flex-col flex-center services-container section-dark full-width-background'>
          <h6>WHAT WE OFFER</h6>
          <h1>Our Services</h1>
          <Service
            title='Web / Mobile Application Development'
            toggleClass={webMobileClass}
            backgroundClass='webMobile'
            onToggle={() => this.onToggleSection('webMobile')}
            img={this.props.data.webmobile}
          >
            <p>
              We have extensive experience in the web / mobile development industry and have built everything from small static sites
              to fully fledged applications. You can trust us to produce highly robust, accessible, responsive, and intutitive user experiences
              that are easily maintanable and adaptive to change over the years.
            </p>
            <p>
              <b>Our Focus:</b>
            </p>
            <ul className='service-quality-list'>
              <ServiceQuality text='Responsive design' />
              <ServiceQuality text='Accessibility' />
              <ServiceQuality text='Intuitive User Experience' />
              <ServiceQuality text='Performance' />
            </ul>
          </Service>
          <Service
            title='Geospatial Application Development'
            toggleClass={geospatialClass}
            backgroundClass='geospatial'
            onToggle={() => this.onToggleSection('geospatial')}
            img={this.props.data.geospatial}
          >
            <p>
              We have extensive experience in building custom geospatial applications to geo-enable customer data. Our team works with customers to
              sanitize data, perform statistical analysis, and provide tools to visualize the data. Our solutions will help your team extract
              siginificant information and relationships from your data that enable you to make important decisions with the utmost confidence.
            </p>
            <p>
              <b>Our Focus:</b>
            </p>
            <ul className='service-quality-list'>
              <ServiceQuality text='Data Visuzlization' />
              <ServiceQuality text='Spacial Analysis / Statistics' />
              <ServiceQuality text='Digital Mapping' />
              <ServiceQuality text='Intuitive User Experience' />
            </ul>
          </Service>
          <Service
            title='Business Intelligence'
            toggleClass={businessIntelligenceClass}
            backgroundClass='businessIntelligence'
            onToggle={() => this.onToggleSection('businessIntelligence')}
            img={this.props.data.businessIntelligence}
          >
            <p>
              We have extensive experience in building custom BI solutions. Our team works with customers to
              extract / transform / load data and provide accurate reporting to help them make informed business decisions with the utmost confidence.
            </p>
            <p>
              <b>Our Focus:</b>
            </p>
            <ul className='service-quality-list'>
              <ServiceQuality text='Accurate Reporting' />
              <ServiceQuality text='Extract Transform Load Workflows' />
              <ServiceQuality text='Meaningful Business Insights' />
              <ServiceQuality text='Custom Reporting' />
            </ul>
          </Service>
          <Service
            title='Cloud Solutions Architecture'
            toggleClass={cloudClass}
            backgroundClass='cloud'
            onToggle={() => this.onToggleSection('cloud')}
            img={this.props.data.cloudSolutions}
          >
            <p>
              PriorityQ has helped numerous clients migrate their infrastructure from on premises facilities into the cloud and has also built
              new infrastructure from the ground up in the cloud. We have done everything from migrating static sites to the cloud to rebuilding Extract Transform Load pipelines
              consisting of highly available multi region data storage and compute infrastructure components. Our team of certified solutions architects can help you choose the right services in a sea
              of services and build a system that is both cost effective and highly functional.
            </p>
            <p>
              <b>Our Focus:</b>
            </p>
            <ul className='service-quality-list'>
              <ServiceQuality text='High Availability and Durability' />
              <ServiceQuality text='Security' />
              <ServiceQuality text='Fault Tolerance' />
              <ServiceQuality text='Cost Effectiveness' />
              <ServiceQuality text='Architectural Integrity' />
            </ul>
          </Service>
        </div>
      </Layout>
    )
  }
}

export default Services;

export const query = graphql`
  query {
    geospatial: file(relativePath: { eq: "geospatial.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    webmobile: file(relativePath: { eq: "webmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    businessIntelligence: file(relativePath: { eq: "businessintelligence.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    cloudSolutions: file(relativePath: { eq: "cloudsolutions.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
