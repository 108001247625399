import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import '../styles/pqCheck.css'

const PqCheck = ({ size, hzPadding, vtPadding, className = '' }) => (
  <div className={`pq-check ${className}`} style={{ padding: `${vtPadding} ${hzPadding}` }}>
    <FontAwesomeIcon icon={faCheck} color='white' size={size} />
  </div>
)

export default PqCheck;